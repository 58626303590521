import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

function ExitConfirmDialog({
  condition,
  discardAndNavigate,
  setShowDialog,
  title = null,
  desc = null,
}) {
  return (
    <Dialog open={condition} onClose={(e, reason) => null}>
      <DialogContent>
        <div className="modal-body" aria-label="feedback-dialog">
          <h2>{title ?? 'Exit Studio'}</h2>
          <p>{desc ?? 'Ensure all your changes are saved before exiting.'}</p>
        </div>
        <div className="modal-btn-block"></div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            variant="contained"
            aria-label="close-dialog-btn"
            className="sm-btn grey-btn"
            onClick={discardAndNavigate}
          >
            Discard and exit
          </Button>
          <Button
            aria-label="feeback"
            className="sm-btn green-btn"
            variant="contained"
            onClick={(e) => setShowDialog(false)}
          >
            Stay on the page
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default ExitConfirmDialog;
