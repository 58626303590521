import * as Sentry from '@sentry/react';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useDispatch } from 'react-redux';

import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import store from './shared/store/store';
import { globalError } from './shared/util/util';
import { setSuccessMsg } from './shared/store/slices/successSlice';

Sentry.init({
  dsn: 'https://ea88c32acaf08f1bbda9822c75587843@o1107930.ingest.sentry.io/4505987632005121',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [process.env.REACT_APP_DOMAIN],
    }),
    new Sentry.Replay(),
  ],
  enabled: process.env.NODE_ENV !== 'development',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const SnackbarMessagesComp = () => {
  const dispatch = useDispatch();

  const handleClose = (key) => {
    closeSnackbar(key);
    globalError(dispatch, '');
    dispatch(setSuccessMsg(''));
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      action={(snackbarKey) => (
        <span
          className="be-close-dark"
          key={snackbarKey}
          onClick={() => handleClose(snackbarKey)}
        ></span>
      )}
      key={new Date().getTime().toString()}
      autoHideDuration={4000}
      iconVariant={{
        success: <span className="me-3 toast-message-success"></span>,
        error: <span className="me-3 toast-message-error"></span>,
      }}
    >
      <App />
    </SnackbarProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <div className="wrapper">
    <Provider store={store}>
      <SnackbarMessagesComp />
    </Provider>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
