import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import '../../building/project.scss';
import './duplicate-buildcard.scss.scss';
import {
  findIcon,
  getInitials,
  snakeCaseToSentence,
} from '../../../shared/util/util';
import { statusColorMapping } from '../../../shared/util/constant';
import moment from 'moment';
import OverflowTooltip from '../../../shared/shared-comp/OverflowTooltip';

const AttributesTable = ({ data }) => {
  const atrtributesList = {
    similarity: 'Similarity',
    space: 'Space',
    completion: 'Progress',
    status: 'Status',
    platforms: 'Platform',
    credits: 'Credit',
    created_at: 'Created on',
    delivery_date: 'Delivery/Duration',
    number_of_journeys: 'Journeys',
    number_of_features: 'Features',
    company_goals: 'Business goals',
    creator: 'Lead',
  };

  const td1Ref = useRef(null);
  const td2Ref = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (td1Ref.current && td2Ref.current) {
      const height1 = td1Ref.current.offsetHeight;
      const height2 = td2Ref.current.offsetHeight;
      setMaxHeight(Math.max(height1, height2));
    }
  }, []);

  const dateFormat = (date) => moment(date).format('DD MMM YYYY');

  //=================================================

  return (
    <>
      {data && (
        <div
          className="duplicate-buildcard-section"
          aria-label="attributes-table"
        >
          <div className="attributes-section">
            <div className="attributes-table left">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Attributes</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(atrtributesList).map((attr) => (
                      <TableRow
                        key={attr}
                        ref={td1Ref}
                        style={{ height: maxHeight }}
                      >
                        <TableCell scope="row">
                          {snakeCaseToSentence(attr)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="attributes-table">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ background: '#F3EBFF' }}>
                        <div className="d-flex align-items-center">
                          {/* {data?.name[0]} */}
                          <OverflowTooltip
                            tooltip={data?.name?.[0]}
                            text={data?.name?.[0]}
                            placement={'top'}
                          />
                          <span
                            className="status-tags"
                            style={{ marginLeft: '5px' }}
                          >
                            {snakeCaseToSentence(data?.state?.[0])}
                          </span>
                        </div>
                      </TableCell>
                      {data?.name?.slice(1)?.map((name) => (
                        <TableCell key={name}>
                          <div className="d-flex align-items-center">
                            <OverflowTooltip
                              tooltip={name}
                              text={name}
                              placement={'top'}
                            />
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {data?.similarity?.map((val, idx) => (
                        <TableCell key={`${val}-${idx}`} scope="row">
                          {val === '-' ? (
                            val
                          ) : (
                            <strong className="progress">
                              <span className="circle-outer">
                                <em
                                  className="circle"
                                  style={{
                                    background: `radial-gradient(circle closest-side, white 0, white 0%, transparent 0%, transparent 100%, white 0), conic-gradient(#7c4dff 0, #7c4dff ${val}%, #fff 0, #fff 100%)`,
                                  }}
                                ></em>
                              </span>
                              {Math.round(val)}%
                            </strong>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {data?.space?.map((spaceName, idx) => (
                        <TableCell key={`${spaceName?.id}-${idx}`} scope="row">
                          <div className="supply-chain">
                            <em className="icon be-multiple-users"></em>
                            <OverflowTooltip
                              tooltip={spaceName?.name ?? '-'}
                              text={spaceName?.name ?? '-'}
                              placement={'top'}
                            />
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {data?.completion?.map((value, idx) => (
                        <TableCell key={`${value}-${idx}`} scope="row">
                          {value === '-' ? '-' : `${Math.round(value)}%`}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {data?.state?.map((state, idx) => (
                        <TableCell key={`${state}-${idx}`} scope="row">
                          {state === '-' ? (
                            state
                          ) : (
                            <span
                              className="status-tags"
                              style={{
                                background:
                                  statusColorMapping[state] ?? '#FFAB00',
                              }}
                            >
                              {snakeCaseToSentence(state)}
                            </span>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {data?.platforms?.map((platforms, idx) => (
                        <TableCell key={platforms.join(',') + idx} scope="row">
                          <span className="platform-col">
                            {platforms?.map((platform) => (
                              <>
                                <img
                                  key={platform}
                                  src={findIcon(platform)}
                                  alt={platform}
                                />{' '}
                              </>
                            ))}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {data?.credits?.map((credit, idx) => (
                        <TableCell key={`${credit}-${idx}`} scope="row">
                          {credit}
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      {data?.created_at?.map((date) => (
                        <TableCell key={date} scope="row">
                          {dateFormat(date) ?? '-'}
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      {data?.delivery_date?.map((date) => (
                        <TableCell key={date} scope="row">
                          {dateFormat(date) ?? '-'}
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow>
                      {data?.number_of_journeys?.map((journey, idx) => (
                        <TableCell key={`${journey}-${idx}`} scope="row">
                          {journey ?? '-'}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {data?.number_of_features?.map((feature, idx) => (
                        <TableCell key={`${feature}-${idx}`} scope="row">
                          {feature ?? '-'}
                        </TableCell>
                      ))}
                    </TableRow>

                    <TableRow ref={td2Ref} style={{ height: maxHeight }}>
                      {data?.company_goals?.map((goals, idx) => (
                        <TableCell key={idx} scope="row">
                          {goals?.map((goal) => (
                            <Chip key={goal?.name} label={goal?.name} />
                          ))}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      {data?.creator?.map((creator, idx) => (
                        <TableCell key={`${creator?.id}-${idx}`} scope="row">
                          <div className="lead-name">
                            <UserProfileIcon user={creator} /> {creator?.name}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const UserProfileIcon = ({ user }) => (
  <div className="initials">
    {user?.profile_picture?.profile_picture_url ? (
      <img
        src={user?.profile_picture?.profile_picture_url}
        alt=""
        style={{ borderRadius: '50%' }}
      />
    ) : (
      getInitials(user?.name)
    )}
  </div>
);

export default AttributesTable;
