import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import instance from './interceptor';
import { addUserData, setCompany, userState } from './store/slices/userSlice';
import { COMPANY, SESSION_DETAIL } from './util/constant';
import {
  clearStorage,
  getCookie,
  globalError,
  setCompanyToStorage,
  setLoader,
  setUserToStorage,
} from './util/util';

const useAuth = () => {
  const dispatch = useDispatch();
  const userSelector = useSelector(userState);

  const [isAuth, setIsAuth] = useState(null);

  useEffect(() => {
    (async () => {
      const userCookie = getCookie(process.env.REACT_APP_USER);
      const token = userCookie ? JSON.parse(userCookie)?.['authtoken'] : null;

      if (!token) {
        clearStorage();
        setIsAuth(false);
      } else {
        if (userSelector && Object.keys(userSelector).length) {
          setIsAuth(true);
        } else {
          await fetchUserDetails();
        }
      }
    })();
  }, [isAuth]);

  const fetchUserDetails = async () => {
    try {
      setLoader(dispatch, true);
      const userCookie = getCookie(process.env.REACT_APP_USER);
      const studioId = userCookie ? JSON.parse(userCookie)?.['id'] : null;
      const res = await instance.get(SESSION_DETAIL, {
        headers: { 'studio-id': studioId },
      });
      const companyRes = await instance.get(
        `${COMPANY}/${res['user']?.company?.id}`
      );
      setUserToStorage(dispatch, addUserData, res, true);
      setCompanyToStorage(dispatch, setCompany, companyRes);
      setIsAuth(true);
    } catch (e) {
      globalError(dispatch, e);
      setIsAuth(false);
    } finally {
      setLoader(dispatch, false);
    }
  };

  return isAuth;
};

const Protected = () => {
  const location = useLocation();
  const isAuth = useAuth();

  if (isAuth === null) return null;

  if (!isAuth) {
    return (
      <Navigate
        to="/signin"
        data-testid="link"
        replace
        state={{ from: location }}
      />
    );
  }

  return (
    <main className="main-content" data-testid="main-content">
      <Outlet />
    </main>
  );
};

export default Protected;
