import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Popover } from '@mui/material';
import images from '../assets/images';
import useOutSideClick from '../shared/hooks/UseOutsideClick';
import instance from '../shared/interceptor';
import LoaderComp from '../shared/shared-comp/LoaderComp';
import UserProfileTooltip from '../shared/shared-comp/UserProfileTooltip';
import { currentSpaceState } from '../shared/store/slices/companySlice';
import { companyState, userState } from '../shared/store/slices/userSlice';
import { SPACES } from '../shared/util/constant';
import {
  debounce,
  getFullname,
  getInitials,
  globalError,
  isPrivateSpaceOwner,
  navigateToSpaceOrProject,
} from '../shared/util/util';
import UnauthorizedAccess from './UnauthorizedAccess';

function Activity() {
  const params = useParams();
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const userSel = useSelector(userState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [recentActivities, setRecentActivities] = useState([]);
  const [earlierActivities, setEarlierActivities] = useState([]);
  const [random, setRandom] = useState(1);
  const [pageMetadata, setPageMetadata] = useState({
    totalCount: 0,
    totalPage: 0,
    nextPage: 1,
    perPage: 0,
  });
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (compSel?.id) {
      getActivities(signal);
    }

    return () => {
      controller.abort();
    };
  }, [searchTerm]);

  const getActivities = async (signal?) => {
    try {
      if (pageMetadata.nextPage) {
        setLoading(true);
        let url = `${SPACES.replace(':id', compSel?.id)}/${params?.id}/activities?page=${pageMetadata.nextPage}`;

        if (searchTerm) {
          url += `&q=${searchTerm}`;
        }
        const resp = await instance.get(url, { signal });
        if (resp) {
          const recent = resp?.['activities'].filter((item) =>
            moment(item?.created_at).isAfter(moment().subtract(7, 'd'))
          );
          const earlier = resp?.['activities'].filter((item) =>
            moment(item?.created_at).isBefore(moment().subtract(7, 'd'))
          );
          setRecentActivities((prevItems) => [...prevItems, ...recent]);
          setEarlierActivities((prevItems) => [...prevItems, ...earlier]);
          setPageMetadata({
            totalCount: resp?.['total_count'],
            totalPage: resp?.['total_pages'],
            nextPage: resp?.['next_page'],
            perPage: resp?.['per_page'],
          });
        }
      }
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setLoading(false);
    }
  };

  const searchActivity = debounce((e) => {
    setPageMetadata({
      totalCount: 0,
      totalPage: 0,
      nextPage: 1,
      perPage: 0,
    });
    setSearchTerm(e?.target?.value);
    setRecentActivities([]);
    setEarlierActivities([]);
  });

  const clearInput = () => {
    setPageMetadata({
      totalCount: 0,
      totalPage: 0,
      nextPage: 1,
      perPage: 0,
    });
    setSearchTerm('');
    setRecentActivities([]);
    setEarlierActivities([]);
    setRandom((prev) => prev + 1);
  };

  const showUserTooltip = (e, activity) => {
    if (
      (e?.target as any)?.nodeName.toLowerCase() === 'strong' &&
      (!(e?.target as any)?.dataset?.spaceId ||
        !(e?.target as any)?.dataset?.buildcardId)
    ) {
      setPopperAnchorEl({ [activity?.id]: e.currentTarget });
    } else {
      setPopperAnchorEl(null);
    }
  };

  const activityRef = useOutSideClick((e) => {
    if ((e?.target as any)?.nodeName.toLowerCase() === 'strong') {
      return;
    }
    setPopperAnchorEl(null);
  });

  const handleUserProfilePopoverClose = (e) => {
    e?.stopPropagation();
    setPopperAnchorEl(null);
  };

  //================================================
  return isPrivateSpaceOwner(currentSpaceSel, userSel) ? (
    <div
      className="project-overview tab-page-section spaces-team-page"
      aria-label="project-activity-page"
    >
      <div className="page-container pb-0">
        {/* <SpaceTopNavigation /> */}

        <section className="timeline-wrapper">
          <div className="page-content">
            <div className="page-filters-wrapper">
              <div className="left-filter">
                <div className="form-group search-container">
                  <FormControl variant="filled">
                    {/* @ts-ignore */}
                    <FilledInput
                      inputProps={{ 'data-testid': 'input-project' }}
                      type="text"
                      className="xs-input search-input-wrap"
                      placeholder="Search in activities..."
                      startAdornment={
                        <InputAdornment position="start">
                          <em className="be-search"></em>
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment
                          position="end"
                          className="search-icon-wrap"
                        >
                          {searchTerm && (
                            <img
                              data-testid="cross-icon"
                              src={images.iconCrossCircle}
                              alt=""
                              onClick={clearInput}
                            />
                          )}
                        </InputAdornment>
                      }
                      key={random}
                      onChange={searchActivity}
                    />
                  </FormControl>
                </div>
              </div>
            </div>

            <div className="activities-wrapper" id="scrollableDiv">
              {!loading &&
                !recentActivities?.length &&
                !earlierActivities?.length && (
                  <div className="no-data-wrapper secondary fixed-height">
                    <em className="thumb">
                      <img src={images.emptyStateIllustration} alt="Empty" />
                    </em>
                    <h4 aria-label="no-space">No activities yet</h4>
                    <p>Currently you don't have any activities.</p>
                  </div>
                )}
              {loading && pageMetadata?.nextPage <= 1 && (
                <LoaderComp border={true} style={{ height: '60vh' }} />
              )}
              {(recentActivities?.length > 0 ||
                earlierActivities?.length > 0) && (
                <div className="activity-list-box">
                  <InfiniteScroll
                    scrollableTarget="scrollableDiv"
                    dataLength={pageMetadata.nextPage * pageMetadata.perPage}
                    next={() => getActivities()}
                    hasMore={!!pageMetadata.nextPage}
                    loader={
                      loading && (
                        <div className="searching-loader">
                          <div className="dot-flashing"></div>
                        </div>
                      )
                    }
                  >
                    {recentActivities?.length > 0 && (
                      <div className="activity-group">
                        <h6>Recent</h6>
                        <div className="recent-activity no-scroll">
                          {recentActivities?.map((activity) => {
                            return (
                              <div className="user-link" key={activity?.id}>
                                <span className="user-avatar">
                                  {activity?.user?.profile_picture
                                    ?.profile_picture_url ? (
                                    <img
                                      src={
                                        activity?.user?.profile_picture
                                          ?.profile_picture_url
                                      }
                                      alt="user profile"
                                    />
                                  ) : (
                                    <span className="initials">
                                      {getInitials(
                                        getFullname(
                                          activity?.user?.first_name,
                                          activity?.user?.last_name
                                        )
                                      )}
                                    </span>
                                  )}
                                </span>
                                <div
                                  className="user-detail"
                                  aria-label="user-detail"
                                >
                                  <div className="basic-info" ref={activityRef}>
                                    <div
                                      onClick={(e) => {
                                        (
                                          e?.target as any
                                        )?.nodeName.toLowerCase() ===
                                          'strong' &&
                                        ((e?.target as any)?.dataset?.spaceId ||
                                          (e?.target as any)?.dataset
                                            ?.buildCardId)
                                          ? navigateToSpaceOrProject(
                                              e,
                                              navigate
                                            )
                                          : showUserTooltip(e, activity);
                                      }}
                                      className="htmlContent"
                                      dangerouslySetInnerHTML={{
                                        __html: activity?.value,
                                      }}
                                    ></div>
                                    <Popover
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      open={Boolean(
                                        popperAnchorEl?.[activity?.id]
                                      )}
                                      className="user-profile-tooltip-popover"
                                      anchorEl={popperAnchorEl?.[activity?.id]}
                                      onClose={handleUserProfilePopoverClose}
                                    >
                                      <UserProfileTooltip
                                        {...{ user: activity?.user }}
                                      />
                                    </Popover>
                                    {/* <span >Annie Cho </span>
                                    <span className='description-text'>kicked off the Buildcard <a href={void (0)}>Warehouse Scanner 2</a></span> */}
                                  </div>
                                  <span className="time">
                                    {activity?.created_date}
                                  </span>
                                </div>
                                {/* TODO: to be enable later
                            <div className="menu-trigger">
                              <em className="trigger-icon be-vertical-dots"></em>
                            </div> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    {earlierActivities?.length > 0 && (
                      <div className="activity-group">
                        <h6>Earlier</h6>
                        <div className="recent-activity no-scroll">
                          {earlierActivities?.map((activity) => {
                            return (
                              <div className="user-link" key={activity?.id}>
                                <span className="user-avatar">
                                  {activity?.user?.profile_picture
                                    ?.profile_picture_url ? (
                                    <img
                                      src={
                                        activity?.user?.profile_picture
                                          ?.profile_picture_url
                                      }
                                      alt="user profile"
                                    />
                                  ) : (
                                    <span className="initials">
                                      {getInitials(
                                        getFullname(
                                          activity?.user?.first_name,
                                          activity?.user?.last_name
                                        )
                                      )}
                                    </span>
                                  )}
                                </span>
                                <div className="user-detail">
                                  <div
                                    className="basic-info"
                                    ref={activityRef}
                                    style={{ position: 'relative' }}
                                  >
                                    <div
                                      onClick={(e) => {
                                        (
                                          e?.target as any
                                        )?.nodeName.toLowerCase() ===
                                          'strong' &&
                                        ((e?.target as any)?.dataset?.spaceId ||
                                          (e?.target as any)?.dataset
                                            ?.buildCardId)
                                          ? navigateToSpaceOrProject(
                                              e,
                                              navigate
                                            )
                                          : showUserTooltip(e, activity);
                                      }}
                                      className="htmlContent"
                                      dangerouslySetInnerHTML={{
                                        __html: activity?.value,
                                      }}
                                    ></div>
                                    <Popover
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                      }}
                                      open={Boolean(
                                        popperAnchorEl?.[activity?.id]
                                      )}
                                      className="user-profile-tooltip-popover"
                                      anchorEl={popperAnchorEl?.[activity?.id]}
                                      onClose={handleUserProfilePopoverClose}
                                    >
                                      <UserProfileTooltip
                                        {...{ user: activity?.user }}
                                      />
                                    </Popover>
                                    {/* <span className="user-name">Annie Cho </span>
                                    <span className='description-text'>kicked off the Buildcard <a href={void (0)}>Warehouse Scanner 2</a></span> */}
                                  </div>
                                  <span className="time">
                                    {activity?.created_date}
                                  </span>
                                </div>
                                {/* TODO: to be enable later
                            <div className="menu-trigger">
                              <em className="trigger-icon be-vertical-dots"></em>
                            </div> */}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              )}
            </div>
          </div>

          {/* <Summary /> */}
        </section>
      </div>
    </div>
  ) : (
    <UnauthorizedAccess />
  );
}

export default Activity;
