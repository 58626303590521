import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ON_BOARDING_WAITING_PAYMENT } from '../../../shared/util/constant';

function SharePurchaseOrderDialog(props) {
  const navigate = useNavigate();

  const conditinalNavigate = () => {
    props.setError('');
    props.setSharePurchaseOrder(false);
    if (props.resendOrder) {
      navigate(ON_BOARDING_WAITING_PAYMENT);
    }
  };

  const handleModalClose = (e, reason) => {
    if (reason && reason == 'backdropClick') return;
    props.setError('');
    props.setSharePurchaseOrder(false);
  };

  //===============================================================

  return (
    <Dialog open={props.sharePurchaseOrder} onClose={handleModalClose}>
      <DialogContent>
        {/* <span data-testid="conditional-navigate" className="modal-close be-close" onClick={conditinalNavigate}></span> */}
        <div className="modal-body">
          {props.resendOrder && (
            <div className="modal-pre-title-info">
              <em className="icon be-info"></em>
              <span className="content" data-testid="content">
                Your previous purchase order mail was sent on{' '}
                <strong>
                  {moment(props?.purchaseOrder?.created_at).format(
                    'DD-MM-YYYY'
                  )}
                </strong>{' '}
                to <strong>{props?.purchaseOrder?.email}</strong>
              </span>
            </div>
          )}
          <div className="modal-title">
            <h2>Share purchase order</h2>
            <p>Send your chosen plan to your finance team for payment.</p>
          </div>
          <div className="modal-form">
            <div className="form-group">
              <FormControl variant="filled">
                <InputLabel>Email</InputLabel>
                {/* @ts-ignore */}
                <FilledInput
                  inputProps={{ 'aria-label': 'email' }}
                  type="email"
                  name="email"
                  value={props.financeEmail}
                  onChange={props.emailChangeHandler}
                  onKeyDown={() => props.setError('')}
                />
              </FormControl>
            </div>
            <p className="sm-text text-danger">{props.error}</p>
            {!props.resendOrder && (
              <p className="sm-text">
                This person will become the finance admin for your account and
                will receive future billing and payment requests. You can
                reassign the finance admin in your account members list.
              </p>
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            data-testid="cancelBtn"
            variant="contained"
            className="grey-btn sm-btn"
            onClick={conditinalNavigate}
          >
            Cancel
          </Button>
          <Button
            data-testid="resendBtn"
            variant="contained"
            className="sm-btn green-btn"
            disabled={!props.financeEmail}
            onClick={props.sendEmail}
          >
            Send purchase order
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default SharePurchaseOrderDialog;
