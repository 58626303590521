import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import images from '../../assets/images';
import '../../assets/scss/main.scss';
import { getFullname, getInitials } from '../util/util';

function UserProfileTooltip({ user, spaceList = null }) {
  //===============================================
  return (
    <div className="profile-tooltip-wrap">
      <div className="user-detail-head">
        <div className="profile-pic" aria-label="profile-pic">
          {user?.profile_picture?.profile_picture_url ? (
            <img
              src={user?.profile_picture?.profile_picture_url}
              alt="Avatar"
            />
          ) : (
            <span className="initials">
              {user?.first_name
                ? getInitials(getFullname(user?.first_name, user?.last_name))
                : '-'}
            </span>
          )}
        </div>
        <div className="user-description">
          <h4>
            {user?.first_name
              ? getFullname(user?.first_name, user?.last_name)
              : '-'}
          </h4>
          <div className="profile">{user?.job_title ?? '-'}</div>
        </div>
      </div>
      <div className="content-wrap">
        <div className="content-row email">
          <img src={images.iconEnvelope} alt="" />
          {user?.email ?? '-'}{' '}
        </div>
        <div className="content-row">
          <em className="icon be-marketing"></em>
          {user?.department ?? '-'}
        </div>
      </div>
    </div>
  );
}

export default UserProfileTooltip;
