import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import instance from '../../../shared/interceptor';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { addUserData, userState } from '../../../shared/store/slices/userSlice';
import { USER_PROFILE } from '../../../shared/util/constant';
import {
  globalError,
  setCookie,
  setLoader,
  setUserToStorage,
  userSystemTimezone,
} from '../../../shared/util/util';

function HandleTimezoneDialog({ shouldOpen }) {
  const dispatch = useDispatch();
  const userSel = useSelector(userState);

  const [open, setOpen] = useState(shouldOpen);

  useEffect(() => {
    if (shouldOpen) {
      setOpen(shouldOpen);
    }
  }, [shouldOpen]);

  const handleClick = (val) => {
    if (val) {
      saveDetails();
    } else {
      setCookie('temp_timezone', userSystemTimezone());
      setOpen(false);
    }
  };

  const saveDetails = async () => {
    try {
      const {
        first_name,
        last_name,
        job_title,
        department,
        location,
        country_code,
        phone_number,
      } = userSel;
      let payload = {
        user: {
          first_name: first_name,
          last_name: last_name,
          job_title: job_title,
          phone_number: phone_number,
          country_code: country_code,
          department: department,
          location: location,
          timezone: userSystemTimezone(),
        },
      };
      setLoader(dispatch, true);
      let url = `${USER_PROFILE.replace(':id', userSel?.company?.id).replace(':user_id', userSel?.id)}`;
      let res = await instance.patch(url, payload);
      setUserToStorage(
        dispatch,
        addUserData,
        {
          user: {
            ...JSON.parse(localStorage.getItem(process.env.REACT_APP_USER)),
            ...res?.['user'],
          },
        },
        true
      );
      setOpen(false);
      dispatch(setSuccessMsg('Timezone updated successfully.'));
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  };

  //===================================================

  return (
    <Dialog
      open={open}
      onClose={(e) => setOpen(false)}
      className="handle-timezone-modal-wrap"
    >
      <DialogContent>
        {/* <span
          className="modal-close be-close-dark"
          onClick={(e) => setOpen(false)}
        ></span> */}
        <div className="modal-body">
          <div className="">
            <p>
              We noticed you are in a different time zone. Would you like to
              update your timezone to {userSystemTimezone()}
            </p>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            variant="contained"
            className="sm-btn grey-btn"
            onClick={() => handleClick(false)}
          >
            No
          </Button>
          <Button
            data-testid="yes-btn"
            variant="contained"
            className="sm-btn green-btn"
            onClick={() => handleClick(true)}
          >
            Yes
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default HandleTimezoneDialog;
