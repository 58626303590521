import {
  Box,
  Button,
  CircularProgress,
  Popover,
  Tab,
  Tooltip,
} from '@mui/material';
import { startTransition, useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import React from 'react';
import '../../building/project.scss';
import './duplicate-buildcard.scss.scss';
import AttributesTable from './AttributesTable';
import JourneysFeaturesTable from './JourneysFeaturesTable';
import RightPageHeader from '../../../shared/shared-comp/RightPageHeader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  debounce,
  findIcon,
  getInitials,
  globalError,
  isAllowedTo,
  redirectTo,
  removeByAttr,
  transNavigation,
} from '../../../shared/util/util';
import { useDispatch, useSelector } from 'react-redux';
import { COMPARISON_ATTRIBUTES, PROJECTS } from '../../../shared/util/constant';
import {
  companyState,
  permissionState,
  userPermissionState,
  userState,
} from '../../../shared/store/slices/userSlice';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import BuildcardComp from '../../../shared/shared-comp/BuildcardComp';
import LoaderComp from '../../../shared/shared-comp/LoaderComp';
import {
  addTag,
  getAllSpaceGoals,
  getAllTags,
  getCurrentSpaceDetail,
  handleDelete,
} from '../../../shared/services/common.service';
import UserProfileTooltip from '../../../shared/shared-comp/UserProfileTooltip';
import useOutSideClick from '../../../shared/hooks/UseOutsideClick';
import instance from '../../../shared/interceptor';
import moment from 'moment';
import OverflowTooltip from '../../../shared/shared-comp/OverflowTooltip';

export default function DuplicateBuildcard() {
  const { state } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userSel = useSelector(userState);
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);

  const [mainBuildCard, setMainBuildCard] = useState(null);
  const [mainBuildCardLoading, setMainBuildCardLoading] = useState(false);

  const [similarBuildCards, setSimilarBuildCards] = useState(null);
  const [similarBuildCardsLoading, setSimilarBuildCardsLoading] =
    useState(false);

  const [similarBcIds, setSimilarBcIds] = useState(null);
  const [similarBcDetails, setSimilarBcDetails] = useState(null);
  const [similarBcDetailsLoading, setSimilarBcDetailsLoading] = useState(null);

  const [selectedBcIndex, setSelectedBcIndex] = useState(0);
  const [spaceGoals, setSpaceGoals] = useState([]);
  const [searchSpaceGoals, setSearchSpaceGoals] = useState([]);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [tagList, setTagList] = useState(null);

  const [tab, setTab] = useState('overview');
  const changeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const entity = {
    id: currentSpaceSel?.id,
    type: 'Space',
  };

  useEffect(() => {
    if (!currentSpaceSel) {
      getCurrentSpaceDetail(dispatch, compSel?.id, params?.id);
    }
    getBuildCardDetail(state?.bcId ?? params?.bc_id);
    fetchSimilarBuildCards();
  }, []);

  useEffect(() => {
    fetchSimilarBuildCardDetails(similarBcIds);
  }, [similarBcIds]);

  useEffect(() => {
    (async () => {
      if (
        isAllowedTo(
          'read',
          'space_goal',
          permissions,
          entity,
          userPermissions
        ) &&
        compSel?.id
      ) {
        let res = await getAllSpaceGoals(dispatch, compSel, params);
        setSpaceGoals(res?.['space_goals']);
        setSearchSpaceGoals(res?.['space_goals']);
      }
    })();
  }, [compSel?.id]);

  const tagChangeHandler = debounce((e, card) => {
    if (e.key === 'Enter') {
      addTags(e.target.value, card);
      handleClose();
    } else {
      getTagList(e.target['value']);
    }
  });

  const handleClose = () => {
    setTagList(null);
  };

  const deleteTag = async (tagId) => {
    let response = await handleDelete(dispatch, compSel, tagId);
    setMainBuildCard((card) => ({
      ...card,
      entity_tags: card?.entity_tags?.filter(
        (item) => item?.id !== response?.['entity_tag']?.id
      ),
    }));
  };

  const addTags = async (tagName, card) => {
    let response = await addTag(compSel, dispatch, tagName, card);
    setMainBuildCard({
      ...card,
      entity_tags: [
        ...card?.entity_tags,
        {
          id: response?.['entity_tag']?.['id'],
          name: response?.['entity_tag']?.['name'],
        },
      ],
    });
    handleClose();
  };

  const getTagList = async (query?) => {
    let tagsRes = await getAllTags(compSel, dispatch, query);
    setTagList(tagsRes['tags']);
  };

  const fetchSimilarBuildCards = async () => {
    try {
      setSimilarBuildCardsLoading(true);
      const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/build_cards/${params?.bc_id}/similar_build_cards_data`;
      const similarBuildCardsRes = await instance.get(url);
      setSimilarBuildCards(similarBuildCardsRes);
      setSimilarBcIds(filterBcIds(similarBuildCardsRes));
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setSimilarBuildCardsLoading(false);
    }
  };

  const getBuildCardDetail = async (buildcardId) => {
    try {
      setMainBuildCardLoading(true);
      const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/build_cards/listing?build_card_ids[]=${buildcardId}`;
      const mainBuildCardRes = await instance.get(url);
      setMainBuildCard(mainBuildCardRes?.['build_cards'][0]);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setMainBuildCardLoading(false);
    }
  };

  const buildCardParams = (build_card_ids) =>
    `${build_card_ids.join('&build_card_ids[]=')}`;

  const fetchSimilarBuildCardDetails = async (build_card_ids) => {
    if (!build_card_ids || build_card_ids?.length == 0) return;
    try {
      setSimilarBcDetailsLoading(true);
      const url = `${PROJECTS.replace(':id', compSel?.id).replace(':space_id', params?.id)}/build_cards/listing?build_card_ids[]=${buildCardParams(build_card_ids)}`;
      const similarBcDetailsRes = await instance.get(url);
      setSimilarBcDetails(similarBcDetailsRes['build_cards']);
    } catch (e) {
      globalError(dispatch, e);
    } finally {
      setSimilarBcDetailsLoading(false);
    }
  };

  const filterBcIds = (buildCards) =>
    buildCards?.map((card) => card?.id).sort();

  const [comparisonInnerTab, setComparisonInnerTab] = useState('attributes');
  const changeComparisonInnerTab = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setComparisonInnerTab(newValue);
  };

  const handleClick = (index) => {
    setSelectedBcIndex(index);
  };

  const searchSpaceGoalList = (event) => {
    let val = event?.target?.value;
    if (val) {
      setSpaceGoals(
        searchSpaceGoals.filter((item) =>
          item?.name?.toLowerCase()?.includes(val?.toLowerCase())
        )
      );
    } else {
      setSpaceGoals(searchSpaceGoals);
    }
  };

  const userProfileRef = useOutSideClick((e) => {
    setPopperAnchorEl(null);
  });

  const createBcComparisonAttributes = (
    mainBuildCard,
    similarBcDetails,
    similarBuildCards
  ) =>
    mainBuildCard && similarBcDetails && similarBuildCards
      ? {
          similarity: [
            '-',
            ...similarBuildCards?.map((card) =>
              Math.round(card?.percentage ?? 0)
            ),
          ],
          ...Object.fromEntries(
            COMPARISON_ATTRIBUTES?.map((attr) => [
              attr,
              [mainBuildCard, ...similarBcDetails].map((card) => {
                return card?.[attr] ?? '-';
              }),
            ])
          ),
        }
      : null;

  const updateGoals = (goal, card, remove = false) => {
    setMainBuildCard(
      remove
        ? {
            ...card,
            space_goals: removeByAttr([...card?.space_goals], 'id', goal?.id),
          }
        : {
            ...card,
            space_goals: [
              ...card?.space_goals,
              { id: goal?.id, name: goal?.['name'] },
            ],
          }
    );
  };

  const dateFormat = (date) => moment(date).format('DD MMM YYYY');

  return (
    <div className="duplicate-buildcard-page" aria-label="duplicate-buildcard">
      <div className="page-container top-space">
        <DuplicateBuildcardHeader
          {...{
            cardName: state?.bcName,
            currentSpace: currentSpaceSel,
            mainBuildCard,
          }}
        />

        <div className="tab-contest">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={changeTab} aria-label="lab API tabs example">
                  <Tab label="Overview" value="overview" />
                  <Tab label="Comparison" value="comparison" />
                </TabList>
              </Box>
              {mainBuildCardLoading ||
              similarBuildCardsLoading ||
              similarBcDetailsLoading ? (
                <LoaderComp />
              ) : (
                <>
                  <TabPanel value="overview">
                    <div
                      className={`duplicate-buildcard-section ${selectedBcIndex === 0 ? 'active' : ''}`}
                    >
                      <div className="col">
                        <div className="dup-buildcard-left">
                          <h3>Your Buildcard</h3>
                          {mainBuildCard && (
                            <BuildcardComp
                              {...{
                                card: mainBuildCard,
                                entity,
                                idx: 0,
                                spaceGoals,
                                searchSpaceGoalList,
                                updateGoals,
                                setShowSearchTags: () =>
                                  console.log('set show search tags'),
                                deleteTag,
                                tagList,
                                addTags,
                                tagChangeHandler,
                              }}
                            />
                          )}
                        </div>
                      </div>
                      <div className="col hide justify-content-center">
                        <div className="similar-buildcard-block active">
                          <div className="line"></div>
                          <h3>{`Similar Buildcard (${similarBcDetailsLoading || !similarBuildCards ? '...' : similarBuildCards?.length})`}</h3>
                          <div>
                            {similarBuildCards?.map((card, index) => (
                              <div
                                className={`similar-card ${index == 0 ? 'first-child' : ''}  ${selectedBcIndex === index ? 'active' : ''}`}
                                onClick={() => handleClick(index)}
                                key={card?.id}
                              >
                                <div className="dividerRow"></div>
                                <div className="head">
                                  Buildcard
                                  <OverflowTooltip
                                    tooltip={card?.bc_name}
                                    text={card?.bc_name}
                                    placement={'top'}
                                  />
                                  {/* <Tooltip
                                    placement="top"
                                    arrow
                                    title={card?.bc_name}
                                    classes={{
                                      popper:
                                        'info-tooltip dashboard-space-name',
                                    }}
                                  >
                                    <span className="card-name add-ellipses">
                                      {card?.bc_name?.substring(0, 25) + '...'}
                                    </span>
                                  </Tooltip> */}
                                </div>
                                <div className="similar-info">
                                  <Tooltip
                                    className="progress-tooltip-popover"
                                    placement="bottom"
                                    enterTouchDelay={0}
                                    title={
                                      <div className="credits">
                                        <span>
                                          {Math.round(card?.percentage ?? 0)}%
                                        </span>
                                      </div>
                                    }
                                  >
                                    <span>
                                      <div className="progress-with-bg">
                                        <CircularProgress
                                          size={48}
                                          variant="determinate"
                                          className="bg-progress"
                                          value={100}
                                        />
                                        <CircularProgress
                                          size={48}
                                          variant="determinate"
                                          value={Math.round(
                                            card?.percentage ?? 0
                                          )}
                                        />
                                        <span className="progress-val">
                                          {Math.round(card?.percentage ?? 0)}%
                                        </span>
                                      </div>
                                    </span>
                                  </Tooltip>
                                  <div className="comm-journey">
                                    <p>
                                      <strong>
                                        {card?.common_journeys_count}
                                      </strong>{' '}
                                      common journey
                                    </p>
                                    <p>
                                      <strong>
                                        {card?.common_features_count}
                                      </strong>{' '}
                                      common features
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col right-buildcard justify-content-end">
                        {similarBcDetails &&
                          similarBcDetails[selectedBcIndex] && (
                            <div className="dup-buildcard-info active">
                              <em className="icon be-corner-arrow"></em>
                              <div className="head">
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <div className="supply-chain">
                                      <em className="icon be-multiple-users"></em>{' '}
                                      {/* {
                                        similarBcDetails[selectedBcIndex]?.space
                                          ?.name
                                      } */}
                                      <OverflowTooltip
                                        tooltip={
                                          similarBcDetails[selectedBcIndex]
                                            ?.space?.name
                                        }
                                        text={
                                          similarBcDetails[selectedBcIndex]
                                            ?.space?.name
                                        }
                                        placement={'top'}
                                      />
                                    </div>
                                    <p>
                                      {/* {similarBcDetails[selectedBcIndex]?.name} */}

                                      <OverflowTooltip
                                        tooltip={
                                          similarBcDetails[selectedBcIndex]
                                            ?.name
                                        }
                                        text={
                                          similarBcDetails[selectedBcIndex]
                                            ?.name
                                        }
                                        placement={'top'}
                                      />
                                    </p>
                                  </div>
                                  <div>
                                    <Tooltip
                                      className="progress-tooltip-popover"
                                      placement="bottom"
                                      enterTouchDelay={0}
                                      title={
                                        <div className="credits">
                                          <span>
                                            {Math.round(
                                              similarBcDetails[selectedBcIndex]
                                                ?.completion ?? 0
                                            )}
                                            %
                                          </span>
                                        </div>
                                      }
                                    >
                                      <span>
                                        <div className="progress-with-bg">
                                          <CircularProgress
                                            size={48}
                                            variant="determinate"
                                            className="bg-progress"
                                            value={100}
                                          />
                                          <CircularProgress
                                            size={48}
                                            variant="determinate"
                                            value={Math.round(
                                              similarBcDetails[selectedBcIndex]
                                                ?.completion ?? 0
                                            )}
                                          />
                                          <span className="progress-val">
                                            {Math.round(
                                              similarBcDetails[selectedBcIndex]
                                                ?.completion ?? 0
                                            )}
                                            %
                                          </span>
                                        </div>
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                                {/* <div className="save-credits">
                            <span className="save-title">You will save</span>
                            <div className="save-credits-row">
                              <span>ON Timeline:</span>
                              <span>6 weeks</span>
                            </div>
                            <div className="save-credits-row">
                              <span>ON credits:</span>
                              <span>100 credits</span>
                            </div>
                          </div> */}
                              </div>
                              <div className="save-credits-info">
                                <div className="credits-row">
                                  <span>Created</span>
                                  <span>
                                    {dateFormat(
                                      similarBcDetails[selectedBcIndex]
                                        ?.created_at
                                    )}
                                  </span>
                                </div>
                                <div className="credits-row">
                                  <span>All journey</span>
                                  <span>
                                    {
                                      similarBcDetails[selectedBcIndex]
                                        ?.number_of_journeys
                                    }
                                  </span>
                                </div>
                                <div className="credits-row">
                                  <span>All features</span>
                                  <span>
                                    {
                                      similarBcDetails[selectedBcIndex]
                                        ?.number_of_features
                                    }
                                  </span>
                                </div>
                                <div className="credits-row">
                                  <span>Platform</span>
                                  <span>
                                    {similarBcDetails[
                                      selectedBcIndex
                                    ]?.platforms?.map((platform) => (
                                      <img
                                        key={platform}
                                        src={findIcon(platform)}
                                        alt="Android"
                                      />
                                    ))}
                                  </span>
                                </div>
                                <div className="credits-row">
                                  <span>Expected delivery</span>
                                  <span>
                                    {dateFormat(
                                      similarBcDetails[selectedBcIndex]
                                        ?.delivery_date
                                    )}
                                  </span>
                                </div>
                                <div className="credits-row">
                                  <span>Credits</span>
                                  <span>
                                    {similarBcDetails[selectedBcIndex]?.credits}
                                  </span>
                                </div>
                              </div>
                              <div className="lead-col" ref={userProfileRef}>
                                <div
                                  className="user-avatar"
                                  onClick={(e) => {
                                    e?.stopPropagation();
                                    setPopperAnchorEl({
                                      [similarBcDetails[selectedBcIndex]
                                        ?.creator?.id]: e.currentTarget,
                                    });
                                  }}
                                >
                                  {similarBcDetails[selectedBcIndex]?.creator
                                    ?.profile_picture?.profile_picture_url ? (
                                    <img
                                      src={
                                        similarBcDetails[selectedBcIndex]
                                          ?.creator?.profile_picture
                                          ?.profile_picture_url
                                      }
                                      alt=""
                                    />
                                  ) : (
                                    getInitials(
                                      similarBcDetails[selectedBcIndex]?.creator
                                        ?.name
                                    )
                                  )}
                                </div>
                                <div className="user-name">
                                  Lead
                                  <span>
                                    {
                                      similarBcDetails[selectedBcIndex]?.creator
                                        ?.name
                                    }
                                  </span>
                                  <Popover
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    open={Boolean(
                                      popperAnchorEl?.[
                                        similarBcDetails[selectedBcIndex]
                                          ?.creator?.id
                                      ]
                                    )}
                                    anchorEl={
                                      popperAnchorEl?.[
                                        similarBcDetails[selectedBcIndex]
                                          ?.creator?.id
                                      ]
                                    }
                                  >
                                    <UserProfileTooltip
                                      {...{
                                        user: similarBcDetails[selectedBcIndex]
                                          ?.creator,
                                      }}
                                    />
                                  </Popover>
                                </div>
                              </div>
                              <div className="view-btn">
                                <Button
                                  className="xs-btn purple-btn btn-full"
                                  onClick={() =>
                                    startTransition(() => {
                                      redirectTo(
                                        {
                                          id: similarBcDetails[selectedBcIndex]
                                            ?.space_id,
                                        },
                                        similarBcDetails[selectedBcIndex],
                                        navigate
                                      );
                                    })
                                  }
                                >
                                  View Buildcard
                                </Button>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="comparison">
                    <div>
                      <Box className="buildcard-sub-tab">
                        <TabContext value={comparisonInnerTab}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                              onChange={changeComparisonInnerTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab label="Attributes" value="attributes" />
                              <Tab
                                label="Journeys and features"
                                value="journeys-and-features"
                              />
                            </TabList>
                          </Box>
                          <TabPanel value="attributes">
                            <AttributesTable
                              data={createBcComparisonAttributes(
                                mainBuildCard,
                                similarBcDetails,
                                similarBuildCards
                              )}
                            />
                          </TabPanel>
                          <TabPanel value="journeys-and-features">
                            <JourneysFeaturesTable
                              mainBuildCard={mainBuildCard}
                              similarBuildCards={similarBcDetails}
                            />
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </TabPanel>
                </>
              )}
            </TabContext>
          </Box>
        </div>
      </div>
    </div>
  );
}

const DuplicateBuildcardHeader = ({
  cardName,
  currentSpace,
  mainBuildCard,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const name = cardName ?? mainBuildCard?.name ?? '...';

  return (
    <div className="main-page-header">
      <div className="page-breacrumb">
        <div className="breadcrumb-wrapper">
          <ul className="breadcrumb-links">
            <li>
              <a href="#">Building</a>
            </li>
            <li>
              <a
                onClick={() =>
                  transNavigation(startTransition, navigate, `/company/spaces`)
                }
              >
                Spaces
              </a>
            </li>
            <li>
              <a
                onClick={() =>
                  transNavigation(
                    startTransition,
                    navigate,
                    `/company/spaces/${currentSpace?.id}/overview`
                  )
                }
              >
                {currentSpace?.name}
              </a>
            </li>
            <li>
              <a
                onClick={() =>
                  transNavigation(
                    startTransition,
                    navigate,
                    `/company/spaces/${currentSpace?.id}/playground/${params?.bc_id}`
                  )
                }
              >
                {name}
              </a>
            </li>
            <li>Duplication review</li>
          </ul>
        </div>
        <RightPageHeader />
      </div>

      <div className="member-title ">
        <div>
          <h2 style={{ marginBottom: '0' }}>
            {`${name} - Similar Buildcards`}
          </h2>
          {/* <p>Add a description for your space</p> */}
        </div>
      </div>
    </div>
  );
};
