import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useDispatch, useSelector } from 'react-redux';

import instance from '../../../shared/interceptor';
import { currentSpaceState } from '../../../shared/store/slices/companySlice';
import { setSuccessMsg } from '../../../shared/store/slices/successSlice';
import { companyState } from '../../../shared/store/slices/userSlice';
import { COMPANY } from '../../../shared/util/constant';
import { globalError, setLoader } from '../../../shared/util/util';

function CancelCreditDialog({
  openCancelCreditRequestDialogue,
  handleClose,
  closeCancelRequestDialogue,
}) {
  const dispatch = useDispatch();
  const compSel = useSelector(companyState);
  const currentSpaceSel = useSelector(currentSpaceState);

  const cancelCreditRequest = async () => {
    try {
      setLoader(dispatch, true);
      const url = `${COMPANY}/${compSel?.id}/plan_credit_subscriptions/${compSel?.active_plan_credit_subscription?.id}/space_credits/${currentSpaceSel?.active_space_credit_id}/space_credit_requests/${openCancelCreditRequestDialogue?.id}/cancel`;
      await instance.patch(url);
      closeCancelRequestDialogue(true);
      dispatch(setSuccessMsg('Request cancelled successfully.'));
    } catch (err) {
      globalError(dispatch, err);
    } finally {
      setLoader(dispatch, false);
    }
  };
  return (
    <Dialog
      open={Boolean(openCancelCreditRequestDialogue)}
      onClose={handleClose}
      className="cancel-request-modal"
      data-testid="cancel-credit-req-modal"
    >
      <DialogContent className="modal-body-wrap">
        <div className="modal-body">
          <div className="modal-title">
            <h2>Cancel credit request</h2>
            <p>Are you sure you want to cancel this credit request?</p>
          </div>
          <div></div>
        </div>
      </DialogContent>
      <DialogActions className="fixed-footer-wrap">
        <div className="fixed-full-footer">
          <Button
            data-testid="cancel-btn"
            variant="contained"
            className="grey-btn sm-btn"
            onClick={closeCancelRequestDialogue}
          >
            Cancel
          </Button>
          <Button
            data-testid="cancel-req-btn"
            variant="contained"
            className="sm-btn green-btn"
            onClick={cancelCreditRequest}
          >
            Yes, cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default CancelCreditDialog;
