import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { startTransition, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import images from '../../assets/images';
import Activity from '../../components/Activity';
import {
  getCurrentSpaceDetail,
  getSpaceCreditData,
} from '../../shared/services/common.service';
import RightPageHeader from '../../shared/shared-comp/RightPageHeader';
import { currentSpaceState } from '../../shared/store/slices/companySlice';
import {
  companyState,
  permissionState,
  userPermissionState,
  userState,
} from '../../shared/store/slices/userSlice';
import {
  ON_BOARDING_WAITING_PAYMENT,
  SPACE_LIST,
} from '../../shared/util/constant';
import {
  canViewPrivateSpace,
  isAllowedTo,
  transNavigation,
} from '../../shared/util/util';
import SpaceGoals from './goals/SpaceGoals';
import SpaceCredits from './SpaceCredits';
import SpaceMeetings from './SpaceMeetings';
import SpaceOverview from './SpaceOverview';
import './spaces.scss';
import SpaceTeam from './SpaceTeam';
import Summary from './summary';

export default function SpaceTopNavigation() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = location;
  const { id } = useParams() ?? { id: null };
  const splitLocation = pathname.split('/');
  const tabValue = splitLocation[splitLocation?.length - 1];

  const currentSpaceSel = useSelector(currentSpaceState);
  const companySel = useSelector(companyState);
  const userSel = useSelector(userState);
  const permissions = useSelector(permissionState);
  const userPermissions = useSelector(userPermissionState);
  const [spaceCreditSel, setSpaceCreditSel] = useState(null);

  const hasActivePlan = () => !!companySel?.active_plan_credit_subscription?.id;

  const [value, setValue] = useState(tabValue);
  const [showLoading, setShowLoading] = useState(true);

  const entity = { id: currentSpaceSel?.id, type: 'Space' };

  useEffect(() => {
    if (companySel?.id) {
      fetchDetails();
    }
  }, [id, companySel?.id]);

  useEffect(() => {
    if (tabValue === 'credits' && !hasActivePlan()) {
      navigate(`/company/spaces/${id}/overview`);
    } else {
      setValue(tabValue);
    }
  }, [id, splitLocation[splitLocation?.length - 1]]);

  useEffect(() => {
    if (
      companySel?.id &&
      currentSpaceSel?.id &&
      id == currentSpaceSel?.id &&
      currentSpaceSel?.active_space_credit_id &&
      isAllowedTo('read', 'space_credit', permissions, entity, userPermissions)
    ) {
      (async () => {
        setShowLoading(true);
        let res = await getSpaceCreditData(
          companySel,
          currentSpaceSel,
          dispatch
        );
        if (res?.['length']) {
          setSpaceCreditSel(res);
          setShowLoading(false);
        }
      })();
    } else {
      setShowLoading(false);
    }
  }, [currentSpaceSel?.active_space_credit_id]);

  const fetchDetails = () => {
    getCurrentSpaceDetail(dispatch, companySel?.id, id);
  };

  const isPrivateSpaceOwner = () => {
    return (
      !currentSpaceSel?.private ||
      (currentSpaceSel?.private &&
        canViewPrivateSpace(userSel, currentSpaceSel))
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    navigate(`/company/spaces/${id}/${newValue}`);
  };

  //===========================================
  return (
    <>
      <div className="page-container top-space pb-0">
        <div className="main-page-header">
          <div className="page-breacrumb">
            <div className="breadcrumb-wrapper">
              <ul className="breadcrumb-links">
                <li>
                  <a
                    onClick={() =>
                      transNavigation(
                        startTransition,
                        navigate,
                        ON_BOARDING_WAITING_PAYMENT
                      )
                    }
                  >
                    Building
                  </a>
                </li>
                <li>
                  {isAllowedTo('update', 'space', permissions) ? (
                    <Link to={SPACE_LIST}>Spaces</Link>
                  ) : (
                    'Spaces'
                  )}
                </li>
                <li>{isPrivateSpaceOwner() && currentSpaceSel?.name}</li>
              </ul>
            </div>
            <RightPageHeader />
          </div>

          {isPrivateSpaceOwner() && (
            <div className="page-title">
              <h2 aria-label="member-heading">{currentSpaceSel?.name}</h2>
              {currentSpaceSel?.description ? (
                <div className="page-filters-wrapper align-items-start">
                  <div className="left-filter">
                    <span aria-label="count" className="count">
                      {currentSpaceSel?.description}
                    </span>
                  </div>
                </div>
              ) : (
                <p>
                  Creating stellar apps for our warehouses, distribution centres
                  and retailers.
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      <TabContext value={value}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          className="tab-list-wrapper projects-top-nav-wrapper"
        >
          <TabList
            onChange={handleChange}
            aria-label=""
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Overview" value="overview" />
            {isAllowedTo(
              'read',
              'space_goal',
              permissions,
              entity,
              userPermissions
            ) && <Tab label="Goals" value="space-goals" />}
            {isAllowedTo(
              'read',
              'space_member',
              permissions,
              entity,
              userPermissions
            ) && <Tab label="Members" value="team" />}
            {hasActivePlan() &&
              isAllowedTo(
                'read',
                'space_credit',
                permissions,
                entity,
                userPermissions
              ) && <Tab label="Credits" value="credits" />}
            <Tab label="Activity" value="activity" />
            <Tab label="Meetings" value="meetings" />
          </TabList>

          <div className="page-filters-wrapper m-0">
            <div className="right-filter full-width justify-right">
              {isAllowedTo(
                'update',
                'buildcard',
                permissions,
                entity,
                userPermissions
              ) && (
                <Button
                  variant="contained"
                  className="sm-btn purple-btn btn-start-working-idea"
                  onClick={() =>
                    navigate(`/company/spaces/${id}/space-playground`)
                  }
                >
                  Start working on an idea
                </Button>
              )}
              <Tooltip
                arrow
                placement="top"
                title="Ideaboard"
                classes={{ popper: 'info-tooltip' }}
              >
                <Button
                  variant="outlined"
                  className="xs-btn is-icon-btn icon btn-idea-board"
                  onClick={() => navigate(`/company/spaces/${id}/ideaboard`)}
                >
                  <img src={images.iconIdeaBoard} alt="Ideaboard" />
                </Button>
              </Tooltip>
            </div>
          </div>
        </Box>

        <div className="tab-context-wrapper">
          <div className="panel-wrapper">
            <TabPanel value="overview">
              <div className="tab-panel-left-section">
                <SpaceOverview />
              </div>
            </TabPanel>
            <TabPanel value="space-goals">
              <div className="tab-panel-left-section">
                <SpaceGoals />
              </div>
            </TabPanel>
            <TabPanel value="team">
              <div className="tab-panel-left-section">
                <SpaceTeam />
              </div>
            </TabPanel>
            {hasActivePlan() && (
              <TabPanel value="credits">
                <div className="tab-panel-left-section">
                  <SpaceCredits {...{ showLoading, spaceCreditSel }} />
                </div>
              </TabPanel>
            )}
            <TabPanel value="activity">
              <div className="tab-panel-left-section">
                <Activity />
              </div>
            </TabPanel>
            <TabPanel value="meetings">
              <div className="tab-panel-left-section">
                <SpaceMeetings />
              </div>
            </TabPanel>
          </div>

          <div className="right-summary-wrapper">
            <Summary />
          </div>
        </div>
      </TabContext>
    </>
  );
}
